module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 1000px)","sm":"(max-width: 720px)","md":"(max-width: 1024px)","l":"(max-width: 1536px)","iconsXl":"(max-width: 2000px)","iconsF":"(max-width: 1490px)","iconsM":"(max-width: 1240px)","iconsS":"(max-width: 750px)","iconsXS":"(max-width: 640px)","iconsXXS":"(max-width: 500px)","iconsXXXS":"(max-width: 300px)","portrait":"(orientation: portrait)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9b196e149bc65d4e1078f2943b726693"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
